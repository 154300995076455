import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: '/'
  },
  {
    id: 'content',
    title: 'Content Editor',
    type: 'section',
    icon: 'image',
    children: [
      {
        id: 'home',
        title: 'Home Page',
        type: 'item',
        icon: 'home',
        url: 'content/home'
      },
      {
        id: 'contact-page',
        title: 'Contact Page',
        type: 'item',
        icon: 'codepen',
        url: 'content/contact-page'
      },
      {
        id: 'about-page',
        title: 'About Page',
        type: 'item',
        icon: 'command',
        url: 'content/about'
      },
      {
        id: 'services-page',
        title: 'Services Page',
        type: 'item',
        icon: 'command',
        url: 'content/services'
      },
      {
        id: 'visa-page',
        title: 'Visa Page',
        type: 'item',
        icon: 'clipboard',
        url: 'content/visa-page'
      },
      {
        id: 'message-page',
        title: 'Message Page',
        type: 'item',
        icon: 'layers',
        url: 'content/message-page'
      },
      {
        id: 'about-page',
        title: 'Careers',
        type: 'item',
        icon: 'command',
        url: 'content/career-page'
      },
      {
        id: 'slider',
        title: 'Slider',
        type: 'item',
        icon: 'columns',
        url: 'slider'
      },
      {
        id: 'about',
        title: 'Customers',
        type: 'item',
        icon: 'command',
        url: 'customer'
      },
      {
        id: 'our_people',
        title: 'Our People',
        type: 'item',
        icon: 'user',
        url: 'our-people'
      },
      {
        id: 'visa_file',
        title: 'Visa Files',
        type: 'item',
        icon: 'file-minus',
        url: 'visa-files'
      },
      {
        id: 'sectors',
        title: 'Sectors',
        type: 'item',
        icon: 'align-justify',
        url: 'sectors'
      },
      {
        id: 'contact',
        title: 'Contact Us Form',
        type: 'item',
        icon: 'codepen',
        url: 'content/contact'
      },
      {
        id: 'newsletter',
        title: 'Newsletter',
        type: 'item',
        icon: 'voicemail',
        url: 'content/newsletter'
      },
      {
        id: 'config',
        title: 'Social Media',
        type: 'item',
        icon: 'cpu',
        url: 'content/config'
      }
    ]
  },
]
